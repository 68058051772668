import {
    bottomUnderLine,
    headerSection,
    mainSection,
    heroText,
} from "./heroArea.module.css";

import FormLayout from "./form";
import * as React from "react";
import {buttonCss, contentDiv, headingText, paraText} from "../../styles/main.module.css";
import {StaticImage} from "gatsby-plugin-image";

const HeroArea = ({countryISO, short, symbol}) => {
    return (
        <>
            <div className={mainSection}>
                <section
                    className={`${headerSection} text-center pt-4 bg-center md:bg-center `}
                >
                    <div className={"text-white text-center relative"}>
                        <div className={"my-7 flex justify-center  align-center"}>
                            <StaticImage
                                className={'h-auto'}
                                width={200}
                                placeholder="dominantColor"
                                src={'../../images/waves/wavesLogo.png'}
                                alt={'photo'}/>
                        </div>

                        <div className={"pt-0 py-8"}>
                            <span className={`${bottomUnderLine} w-1/4 md:w-20`}/>
                        </div>
                        <div className={'sm:hidden'}>
                            <div className={`mb-5 m-auto  leading-6  text-center  tracking-widest ${heroText}`}>
                                WATERFRONT FACING <br/> APARTMENTS IN MOHAMMED  <br/> BIN RASHID CITY
                            </div>
                        </div>
                        <div className={'hidden sm:block'}>
                            <div className={`mb-5 m-auto  leading-6  text-center  tracking-widest ${heroText}`}>
                                WATERFRONT FACING  APARTMENTS <br/> IN MOHAMMED  BIN RASHID CITY
                            </div>
                        </div>
                        <div>
                            <p className={"m-auto mb-5 text-xs sm:text-sm md:max-w-xl w-2/3"}>
                                Beautifully designed 1 and 2 bedroom waterfront apartments in Sobha Harland with great
                                views! Residences with smart-home technologies.
                            </p>
                        </div>
                        <div>
                            <p className={"m-auto text-xs sm:text-sm tracking-wider md:max-w-xl w-3/4"}>
                                AVAILABLE FROM:
                            </p>
                        </div>
                        <div>
                            <h1 className={"m-auto text-3xl font-bold md:max-w-xl w-3/4"}>
                                {/*AED 975,000{" "}*/}
                                {symbol} {short}
                            </h1>
                        </div>
                    </div>
                </section>

                <FormLayout countryISO={countryISO}/>

                <div className={'py-12'}>
                    <div className={`${contentDiv} container`}>
                        <h2 className={headingText}>OWN A LUXURY APARTMENT IN DUBAI <br/> SOBHA & PAY 2 YEAR POST
                            HANDOVER</h2>
                        <p className={paraText}>Hartland Waves, by the Waterfront District, is a crisp clean
                            architecture that stands tall in front of the 1.8 km boardwalk that Sobha Hartland enjoys on
                            it’s waterfront side. The façade is a simple play of horizontal and vertical forms that give
                            uninterrupted 360-degree views to the tower, that will enjoy the sunrises and sunsets of
                            every beautiful day</p>
                    </div>

                    <div className={'py-10 text-center'}>
                        <p><a className={buttonCss} href="#">Open Brochure</a></p>

                    </div>

                </div>
            </div>
        </>
    );
};

export default HeroArea;
