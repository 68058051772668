import {
    contentDiv,
    firstGrid,
    headingText, eminityImageText,
    paraText, buttonCss,
} from "../../styles/main.module.css";
import * as React from "react";
import image9 from "../../images/waves/pool.png";
import image10 from "../../images/waves/smartHome.png";
import image11 from "../../images/waves/indoorChild.png";
import image12 from "../../images/waves/bbq.png";
import image13 from "../../images/waves/fitness.png";
import image14 from "../../images/waves/cinema.png";
import {StaticImage} from "gatsby-plugin-image";


const Eminity = () => {
    return (<>
        <div className={'pb-9 sm:pb-16'}>
            <StaticImage
                //placeholder="dominantColor"
                width={1500}
                height={600}
                src={'../../images/waves/banner3.webp'}
                alt={'photo'}/>
        </div>
        <div className={`${contentDiv} container`}>
            <div>
                <h2 className={headingText}>AMENITIES THAT YOU WILL LOVE!</h2>
                <p className={paraText}>The exclusive residential development offers luxury one and two-bedroom
                    apartments. Residents can enjoy panoramic views of the Dubai Skyline, Ras Al Khor Wildlife Sanctuary
                    and Dubai Creek.</p>
            </div>

            <div className={'sm:my-20 my-10'}>
                <div
                    className={`sm:gap-y-24 w-3/4 m-auto grid grid-cols-2 md:mb-4 md:grid-cols-3 lg:grid-cols-3 gap-4 ${firstGrid}`}>
                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}>
                            <img src={image9}
                                 alt=""/>
                        </div>
                        <p className={eminityImageText}><span>Childrens & <br/> Social Pools</span></p>

                    </div>
                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image10}/></div>
                        <p className={eminityImageText}><span>Smart Home <br/> Automation</span></p>

                    </div>

                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image11}/></div>
                        <p className={eminityImageText}><span>Indoor Childrens <br/> Play / Day Care</span></p>

                    </div>


                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image12}/></div>

                        <p className={eminityImageText}><span>BBQ <br/> Area</span></p>

                    </div>

                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image13}/></div>
                        <p className={eminityImageText}><span>Fitness <br/> Centre</span></p>


                    </div>

                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image14}/></div>

                        <p className={eminityImageText}><span>Outdoor <br/> Cinema</span></p>

                    </div>


                </div>


            </div>

            <div className={'pb-8 pt-8'}>
                <p><a className={buttonCss} href="#">Open Prices</a></p>

            </div>

        </div>
    </>)
}

export default Eminity
