import {
    buttonCss,
    contentDiv,
    headingText
} from "../../styles/main.module.css";
import {phaseText, phaseCss, paymentBox} from "../aziziComp/plans.module.css";
import * as React from "react";
import {StaticImage} from "gatsby-plugin-image";

const Plans = () => {
    return (<div className={phaseCss}>

            <div className={'pb-9 sm:pb-16'}>
                <StaticImage
                        //placeholder="dominantColor"
                    width={1500}
                    height={600}
                    src={'../../images/waves/banner4.webp'}
                    alt={'photo'}/>
            </div>
            <div className={`${contentDiv} container`}>
                <div>
                    <h2 className={headingText}>2 YEAR POST HANDOVER PAYMENT PLAN</h2>
                </div>

                <div className={'my-10'}>
                    <div className="grid grid-cols-1 sm:grid-cols-1 gap-4">
                        <div className="text-center">
                            <p className={'mb-3'}>
                                <a className={phaseText}>
                                    <strong
                                        className={'font-light'}>One Price Across All Floors
                                    </strong>
                                </a>
                            </p>
                            <p className={'text-xs mb-2'}>1 BR Apartment Starting at <strong className={'font-medium'}>AED
                                975K ($265K)</strong></p>
                            <p className={'text-xs mb-2'}>1 BR + Study Apartment Starting at <strong
                                className={'font-medium'}>AED 1.1 M ($321K)</strong>
                            </p>
                            <p className={'text-xs mb-2'}>2 BR Apartment Starting at <strong className={'font-medium'}>AED
                                1.65 M ($449,230K)</strong></p>

                        </div>
                    </div>


                    <div
                        className={'my-14 text-center text-primary-gray italic font-thin grid grid-cols-2 sm:grid-cols-4 gap-4'}>

                        <div className={`${paymentBox} px-5 py-10`}>
                            <p>
                                10% Down<br/>
                                Payment
                            </p>
                        </div>
                        <div className={`${paymentBox} px-5 py-10`}>
                            <p>
                                40% During<br/>
                                Construction
                            </p>
                        </div>
                        <div className={`${paymentBox} px-5 py-10`}>
                            <p>
                                50% Over 2 Years<br/>
                                Post Handover
                            </p>
                        </div>
                        <div className={`${paymentBox} px-5 py-10`}>
                            <p>
                                September 2023<br/>
                                Completion
                            </p>
                        </div>

                    </div>


                </div>


                <div>
                    <h2 className={headingText}>CURRENT PROMOTIONS</h2>


                    <div className={'my-5 hidden m-auto sm:flex justify-around items-center w-3/5'}>
                        <div>
                            <StaticImage
                                className={'m-auto sm:w-20 sm:h-20 w-12 h-12'}
                                    placeholder="dominantColor"

                                src={'../../images/current.png'}
                                alt={'photo'}/>
                            <p className={' mt-2 text-xxs text-primary-lightGray tracking-widest'}><strong
                                className={'text-primary-gray'}>50%</strong> DLD <br/>WAIVER</p>

                        </div>
                        <div>
                            <StaticImage
                                className={'m-auto sm:w-20 sm:h-20 w-12 h-12'}
                                    placeholder="dominantColor"

                                src={'../../images/current-3.png'}
                                alt={'photo'}/>
                            <p className={'mt-2 text-xxs text-primary-lightGray tracking-widest'}><strong
                                className={'text-primary-gray'}>2</strong> YEARS FREE<br/>SERVICE CHARGE
                            </p>
                        </div>
                        <div>
                            <StaticImage
                                className={'m-auto sm:w-20 sm:h-20 w-12 h-12'}
                                    placeholder="dominantColor"

                                src={'../../images/current2.png'}
                                alt={'photo'}/>
                            <p className={'mt-2 text-xxs text-primary-lightGray tracking-widest'}><strong
                                className={'text-primary-gray'}>2</strong>Years <br/>POST HANDOVER
                            </p>
                        </div>
                    </div>
                    <div className={'my-5 sm:hidden w-2/3 m-auto'}>

                        <div className={'flex items-center mb-5'}>
                            <StaticImage
                                className={'w-12 h-12 mr-3'}
                                    placeholder="dominantColor"

                                src={'../../images/current.png'}
                                alt={'photo'}/>
                            <p className={' mt-2 text-xxs text-primary-lightGray tracking-widest'}><strong
                                className={'text-primary-gray'}>50%</strong> DLD <br/>WAIVER</p>

                        </div>
                        <div className={'flex items-center mb-5'}>
                            <StaticImage
                                className={'w-12 h-12 mr-3'}
                                    placeholder="dominantColor"

                                src={'../../images/current-3.png'}
                                alt={'photo'}/>
                            <p className={'mt-2 text-xxs text-primary-lightGray tracking-widest'}><strong
                                className={'text-primary-gray'}>2</strong> YEARS FREE<br/>SERVICE CHARGE
                            </p>
                        </div>
                        <div className={'flex items-center'}>
                            <StaticImage
                                className={'w-12 h-12 mr-3'}
                                    placeholder="dominantColor"

                                src={'../../images/current2.png'}
                                alt={'photo'}/>
                            <p className={'mt-2 text-xxs text-primary-lightGray tracking-widest'}><strong
                                className={'text-primary-gray'}>2</strong>Years <br/>POST HANDOVER
                            </p>
                        </div>


                    </div>


                    <div className={'pb-8 pt-8'}>
                        <p><a className={buttonCss} href="#">Enquiry Now</a></p>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Plans
