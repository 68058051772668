import * as React from "react";
import {} from "../components/wavesComp/heroArea.module.css";
import NavHeader from "../components/NavHeader";
import HeroArea from "../components/wavesComp/heroArea";
import Connectivity from "../components/wavesComp/connectivities";
import Eminity from "../components/wavesComp/eminity";
import Plans from "../components/wavesComp/plans";
import Footer from "../components/wavesComp/footer";
import {StaticImage} from "gatsby-plugin-image";
import ModalPage from "../../src/components/modalPage";
import SEO from "../components/App/SEO";
import {siteUrl} from "../components/_utils";
import {useEffect, useState} from "react";
import {getCountry} from "../action";
import {useScrollTracker} from "react-scroll-tracker";
import ReactGA from "react-ga";
import aiziBackground from '../images/aziziBackground.jpg'
const shortNumber = require('short-number')

let dollarAmount = 265450

const Waves = ({data}) => {
    const [countryISO, setCountryISO] = useState('ae')
    const [short, setShort] = useState(shortNumber(dollarAmount))
    const [symbol, setSymbol] = useState('$')

    useEffect(() => {
        getCountry({amount: dollarAmount}).then((data) => {
            console.log(data)

            if (data.short) {
                setShort(data.short)
            }
            if (data.symbol) {
                setSymbol(data.symbol)
            }
            if (data.iso) {
                setCountryISO(data.iso.toLowerCase())
            }
        })
    }, [])

    if (typeof window !== 'undefined') {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useScrollTracker([10, 15, 25, 50, 75, 100], ({scrollY}) => {
            ReactGA.event({
                category: 'scroll',
                action: scrollY,
            })
        })
    }

    const logoComp = (
        <StaticImage
            className="w-24 md:w-32 lg:w-46  align-middle"
            placeholder="tracedSVG"
            src={"../images/waves/logo.png"}
            alt={"Azizi "}
        />
    );
    const titleParams = {
        title: `Waves-Sobha Harland | ${siteUrl}`,
        ogTitle: `Waves-Sobha Harland | ${siteUrl}`,

        description: 'Beautifully designed 1 and 2 bedroom waterfront apartments in Sobha Harland with great views! Residences with smart-home technologies.',
        ogDescription: 'Beautifully designed 1 and 2 bedroom waterfront apartments in Sobha Harland with great views! Residences with smart-home technologies.',
        keywords: '2 YEAR POST HANDOVER PAYMENT PLAN',

        ogImage:aiziBackground,
        ogImageAlt: 'Sobha Harland',
        secureImageUrl: aiziBackground,

        pageLink: `${siteUrl}waves/`,
        orUrl: `${siteUrl}waves/`,
        canonicalRef: `${siteUrl}waves/`,

    }
    return (
        <>
            <SEO data={titleParams}/>
            <NavHeader logoComp={logoComp}/>
            <HeroArea countryISO={countryISO} short={short} symbol={symbol}/>
            <Connectivity/>
            <Eminity/>
            <Plans/>
            <Footer/>
            <ModalPage/>
        </>
    );
};

export default Waves;
