import {
    floatingBox,
    input
} from "../aziziComp/form.module.css";
import * as React from "react";
// import ReactFlag from "../reactFlagsComp"
import {useForm} from "react-hook-form";
import {addForm} from "../../action";
import {siteUrl} from "../_utils";
import {loaderText, telinputContainer} from "../fiveJBR/form.module.css";
import ReactFlag from "../reactFlagsComp-back";
import {ImSpinner} from "react-icons/im";


const FormLayout = (props) => {
    const {register, handleSubmit, formState: {errors}} = useForm();
    let [mobile, setMobile] = React.useState('');
    let [phoneCode, setCode] = React.useState('');
    const [loading, setLoading] = React.useState(false)

    const onSubmit = async (data) => {
        if (!mobile) {
            alert("Mobile Number is required");
            return;
        }
        if (!phoneCode) {
            alert("Phone Code  is required");
            return;
        }



            setLoading(true)


            let x = await addForm({...data,form:'waves',phoneCode, mobile: mobile})
            setLoading(false)

            if (!x.error) {
                window.location.href = `${siteUrl}thankuPage`
            } else {
                console.log(x)
            }



    }


    return (<div className={`sm:px-5 md:max-w-2xl w-11/12 m-auto bg-white ${floatingBox}`}>
            <div className={'p-6 '}>

                <div className={'text-l text-center text-sm text-primary-gray'}>
                    Register you interest today and book your <strong>free</strong> consultation now!
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={'my-4'}><input
                        className={`w-full border border-primary-gray border-opacity-20 py-2 px-4 text-primary-gray text-sm `}
                        type="text" placeholder="Name" id={'name'} {...register('name')} required={true}/></div>
                    <div className={'flex my-4'}>
                        <div className={`border border-primary-gray border-opacity-20 ${telinputContainer}`}>
                            <ReactFlag countryISO={props.countryISO} footerFlag={false} flagValue={(value) => {
                                if(value && value.mobile){
                                    setMobile(value.mobile);
                                    setCode(value.code);
                                }else{
                                    if(value.mobile){
                                        setMobile('')
                                    }
                                    if(value.code){
                                        setCode('')
                                    }
                                }

                            }}/>
                        </div>

                    </div>
                    <div className={'my-4'}><input
                        className={`w-full border border-primary-gray border-opacity-20 py-2 px-4 text-primary-gray text-sm `}
                        type="email" placeholder="Email" id={'email'} {...register('email')} required={true}/></div>
                    <div className={'my-4'}>
                        <button disabled={loading}
                                style={{flex: 1, alignContent: 'center'}} className={'w-full text-sm hover:bg-primary-gray bg-primary-light py-2 px-4 text-white'}
                                type="submit">
                            <span className={loaderText}>{loading && <ImSpinner className={'icon-spin '}/>}</span>

                            <span>Submit</span>

                        </button>
                    </div>

                </form>

            </div>

        </div>
    )
}

export default FormLayout
