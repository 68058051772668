import {
    buttonCss,
    contentDiv,
    firstGrid,
    gridText,
    headingText, iconLine,
    minClass,
    minTextClass,
    paraText
} from "../../styles/main.module.css";
import mall from "../../images/waves/mall.png";
import maydan from "../../images/waves/meydan.png";
import difc from "../../images/waves/difc.png";
import school from "../../images/waves/school.png";
import opera from "../../images/waves/opera.png";
import golf from "../../images/waves/golf.png";
import flight from "../../images/DUBAI-INTERNATIONAL.png";
import palm from "../../images/THE-PALM-JUMEIRAH.png";
import * as React from "react";
import {StaticImage} from "gatsby-plugin-image";


const Connectivity = () => {
    return (<>
        <div className={'pb-9 sm:pb-16'}>
            <StaticImage
                width={1500}
                height={600}
                src={'../../images/waves/banner2.webp'}
                alt={'photo'}
                //placeholder="dominantColor"

            />
        </div>
        <div className={`${contentDiv} container`}>
            <div>
                <h2 className={headingText}>IDEAL LOCATION IN BUSINESS BAY
                </h2>
                <p className={paraText}>Waves is located in an exclusive Waterfront District within the luxurious Sobha
                    Hartland. One of Dubai’s finest and prime locations, Sobha Hartland is just minutes from the major
                    landmarks of the city, like the Burj Khalifa, and Downtown Dubai.</p>
            </div>
            <div className={'sm:my-20 my-10'}>

                <div
                    className={`grid grid-cols-2  sm:grid-cols-3 md:grid-cols-4 gap-0 sm:gap-y-20 sm:gap-x-12 ${firstGrid}`}>
                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={mall}/></div>
                        <p className={gridText}>DUBAI MALL</p>
                        <p className={minClass}>10</p>
                        <p className={minTextClass}>MIN</p>
                        <div className={'sm:hidden pt-0 py-4 w-2/3'}>
                            <span className={`${iconLine} `}/>
                        </div>
                    </div>
                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={flight}/></div>
                        <p className={gridText}>DXB INT. AIRPORT</p>
                        <p className={minClass}>10</p>
                        <p className={minTextClass}>MIN</p>
                        <div className={'sm:hidden pt-0 py-4 w-2/3'}>
                            <span className={`${iconLine} `}/>
                        </div>
                    </div>

                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={difc}/></div>
                        <p className={gridText}>DIFC</p>
                        <p className={minClass}>10</p>
                        <p className={minTextClass}>MIN</p>
                        <div className={'sm:hidden pt-0 py-4 w-2/3'}>
                            <span className={`${iconLine} `}/>
                        </div>
                    </div>


                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={golf}/></div>
                        <p className={gridText}>GOLF CLUB</p>
                        <p className={minClass}>10</p>
                        <p className={minTextClass}>MIN</p>

                        <div className={'sm:hidden pt-0 py-4 w-2/3'}>
                            <span className={`${iconLine} `}/>
                        </div>

                    </div>

                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={palm}/></div>
                        <p className={gridText}>PALM JUMEIRAH</p>
                        <p className={minClass}>10</p>
                        <p className={minTextClass}>MIN</p>

                        <div className={'sm:hidden pt-0 py-4 w-2/3'}>
                            <span className={`${iconLine} `}/>
                        </div>

                    </div>


                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={school}/></div>
                        <p className={gridText}>INT. SCHOOLS</p>
                        <p className={minClass}>10</p>
                        <p className={minTextClass}>MIN</p>

                        <div className={'sm:hidden pt-0 py-4 w-2/3'}>
                            <span className={`${iconLine} `}/>
                        </div>

                    </div>


                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={opera}/></div>
                        <p className={gridText}>DUBAI OPERA</p>
                        <p className={minClass}>10</p>
                        <p className={minTextClass}>MIN</p>


                    </div>


                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={maydan}/></div>
                        <p className={gridText}>MEYDAN</p>
                        <p className={minClass}>10</p>
                        <p className={minTextClass}>MIN</p>

                    </div>


                </div>

                <div className={'pt-14 sm:pt-16'}>
                    <p><a className={buttonCss} href="#">Talk to a Consultant</a></p>

                </div>
            </div>

        </div>
    </>)
}

export default Connectivity
