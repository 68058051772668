
import {FaWhatsapp} from "react-icons/fa";
import * as React from "react";
import FooterFormLayout from "../aziziComp/footerForm";
import footerImage from "../../images/BNW_logo_png.png";
import {StaticImage} from "gatsby-plugin-image";

const Footer = () => {
    return (<>
        <div className={'mb-16 mt-8'}>
            <div className={'pb-9 sm:pb-12'}>
                <StaticImage
                    //placeholder="dominantColor"
                    width={1500}
                    src={'../../images/waves/banner5.webp'}
                    alt={'photo'}/>
            </div>

        </div>
        <FooterFormLayout showPara={true}  heading={'BOOK A FREE CONSULTATION'} buttonName={'Submit'}/>
        <div className={'pt-10 text-center container'}>
            <div className={'mb-8'}>
                <p className={'text-xs leading-6 text-primary-lightGray'}>Exclusive Waterfront District</p>
                <p className={'text-xs leading-6 text-primary-lightGray'}>Directly on the Dubai Canal</p>
                <p className={'text-xs leading-6 text-primary-lightGray'}>Mesmerising views of Downtown Dubai, Dubai Creek, Dubai Canal and Ras Al Khor</p>
                <p className={'text-xs leading-6 text-primary-lightGray'}>1 Bed starting from AED 975 ($265k)</p>
                <p className={'text-xs leading-6 text-primary-lightGray'}>Pay over 2 years after move-in</p>
                <p className={'text-xs leading-6'}><strong className={'font-medium'}>Estimated Completion: September 2023</strong></p>
            </div>


            <div className={'pb-16'}>
                <p><a className={'inline-flex items-center py-3 px-8 border text-xs sm:text-sm  text-primary-green border-primary-green '}
                      href="#"><FaWhatsapp className={'sm:text-2xl text-xl mr-3  text-primary-green '}/> We
                    Are Online</a>
                </p>

            </div>


        </div>

        <div className={'bg-primary-gray'}>
            <div className={'container  py-16'}>
                <img className={'m-auto'} src={footerImage}
                     alt=""/>
            </div>

        </div>
    </>)
}

export default Footer
